<template>
   <transition name="content-tab-modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="image-nav-container">
          <div>
              <i class="close" v-on:click="$emit('close')">
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"/>
  <path fill-rule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"/>
              </svg>  
              </i>  
              <i class="btn-download" v-on:click="download"><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-download" viewBox="0 0 16 16">
  <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
  <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
              </svg></i>  
          </div>         
        </div>
        <div class="modal-container">
          <div class="modal-body">
             <div class="btn-side-container" v-on:click="Previous()">
               <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="white" class="bi bi-arrow-left-circle" viewBox="0 0 16 16" v-if="activeNum >0">
                <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"/>
              </svg>
            </div>
            <div class="modal-image-container">         
                <img v-bind:src="activeImage" alt="">
            </div>
            <div class="btn-side-container" v-on:click="Next()" >
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="white" class="bi bi-arrow-right-circle" viewBox="0 0 16 16" v-if="activeNum < (imagesObj.length-1)">
                <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>
            </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
    name:"PictureModal",
    props:{
        selectedImage:{
            Type:String
        },
        fileName:{
          Type:String
        },
        imagesObj:{
          Type:Object
        },
        active:{
          Type:Number
        }
    },
    data(){
      return {
        activeImage:"",
        activeFileName:"",
        activeNum:0,
      }
    },
    watch:{
      selectedImage(param_new,param_old){
        this.activeImage = param_new;
         this.activeNum = this.active;
      },
      fileName(param_new,param_old){
        this.activeFileName = param_new;
      }
    },
    mounted(){
      document.addEventListener('keydown',this.keyPressed);
    },
    methods: {
      close() {
        this.$emit('close');
      },
      download(){
        var a = document.createElement("a");
          a.href = this.activeImage;
          a.download = this.activeFileName;
          a.click();
      },
      Previous(){
         this.activeNum--;
         if(this.activeNum == -1){
           this.activeNum = 0;
         }
         this.$emit('changePicture',this.activeNum);
      },
      Next(){
        this.activeNum++;
        this.$emit('changePicture',this.activeNum);
      },
      keyPressed(event){
        if(event.keyCode == 39)
        {
          this.Next();
        }
        else if(event.keyCode == 37)
        {
          this.Previous();
        }
      }

    }
}
</script>
<style>
  
.modal-image-container{
  height: 100%;
    width: 100%;
}
.modal-image-container > img{
  height:100%;
  width:100%;
  object-fit:contain;
}
/*  Modal Style */
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .9);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    flex-direction:column;
}

.modal-container {
  width: 50%;
    height: 80%;
    padding: 0;
    background: transparent;
    transition: all .3s ease;
}
.close:hover ,  .btn-download:hover{
  cursor: pointer;
}

.modal-body {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.btn-side-container{
  width: 20%;
  display:flex;
  justify-content: center;
  align-items: center;
}
.btn-side-container:hover{
  cursor: pointer;
}
.modal-default-button {
  float: right;
}
.btn-download{
  right: -22% !important;
}
.modal-body > i:hover{
cursor:pointer; 
}
.image-nav-container{
  height: 10%;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: white;
}

.image-nav-container > div{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;
  width: 6%;
  flex-direction: row-reverse;
  margin-right: 4%;
}

/*modal transitions*/

.content-tab-modal-enter {
    opacity: 0;
}

.content-tab-modal-leave-active {
    opacity: 0;
}

.content-tab-modal-enter-active>.modal-wrapper {
    animation: slide-in-bottom 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.content-tab-modal-leave-active>.modal-wrapper {
    animation: slide-out-bottom 0.3s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

.content-tab-modal-enter .modal-container,
.content-tab-modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

@media screen and (max-width: 762px) {
  .modal-container{
      width: 100% !important;
  }
  .image-nav-container > div{
      width: 20% !important;
    
  }
}
@media screen and (width: 280px) {
  .modal-container{
      width: 100% !important;
  }
  .image-nav-container > div{
      width: 28% !important;
    
  }
}
@media screen and (width: 768px) {
  .modal-container{
      width: 80% !important;
  }
  .image-nav-container > div{
      width: 13% !important;
    
  }
}
@media screen and (width: 820px) {
  .modal-container{
      width: 80% !important;
  }
   .image-nav-container > div{
      width: 10% !important;  
  }
}
@media screen and (width: 912px) {
  .modal-container{
      width: 80% !important;
  }
   .image-nav-container > div{
      width: 10% !important;  
  }
}
@media screen and (width: 1024px) {
  .modal-container{
      width: 40% !important;
  }
   .image-nav-container > div{
      width: 8% !important;  
  }
}
@media screen and (width: 1280px) {
  .modal-container{
      width: 45% !important;
  }
   .image-nav-container > div{
      width: 8% !important;  
  }
}
</style>